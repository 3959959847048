/************ Custom Font ************/
@import 'animate.css';

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./assets/fonts/helvetica-neu.ttf');
}


@font-face {
	font-family: 'Almarai Reg';
	src: url('./assets/fonts/Almarai-Regular.ttf');
}


@font-face {
	font-family: 'Kanit-ExtraLight';
	src: url('./assets/fonts/Kanit-ExtraLight.ttf');
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Almarai Reg' !important;
  font-weight: 800;
}


h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: 'Kanit-ExtraLight';
  font-weight: 300;
  letter-spacing: 2.5px;
}


h1 {
  margin: 0;
  padding: 0;
  line-height: normal;
  height: 130px;
  font-family: 'Kanit-ExtraLight';
  font-weight: 50;

}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
  animation: pulse 1s forwards;
  font-family: 'Helvetica' ;
  font-size: large;
  color: #ffffff;

}



a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

.profile-image {
  width: 375px; 
  height: 375px; 
  object-fit: cover; 
  border-radius: 50%;
  overflow: hidden; 
  display: block; 
  margin: 0 auto; 
}


/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}


nav.navbar a.navbar-brand {
    width: 7%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff ;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}


nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
  text-shadow: 
  0 0 10px rgba(255, 255, 255, 0.9),  /* Small glow */
  0 0 20px rgba(255, 255, 255, 0.9),  
  0 0 30px rgba(255, 255, 255, 1),    
  0 0 40px rgba(255, 255, 255, 1);    /* larger glow */  
  opacity: 1;
}


nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}


span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 15px;
  height: 15px;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
  
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/images/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  position: relative;
  padding: 0 0 20px 0;  
}

.background-image-left,
.background-image-right {
  position: absolute;
  top: 0; 
  bottom: 0; 
  width: 80%;  
  background-size: cover;  
  background-position: center; 
  z-index: -4;
}

.background-image-left {
  left: 0;
}

.background-image-right {
  right: 0; 
  top: 60px;

}

.skill-bx {
  background: #151515;
  border-radius: 30px;  
  text-align: center;
  padding: 30px 30px;  
  margin-top: -10px; 
}
.skill h2 {
  font-size: 50px;  
  font-weight: 700;
  color: #ffffff;
}
.skill p {
  color: #B8B8B8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 24px auto 30px auto;
  text-align: center;
  width: 90%;
}

.skill-slider {
  overflow: hidden;
  position: absolute;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.face {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.skill-item img {
  height: 40px;
  width: 40px;

}


.skill-name {
  margin-top: 10px;
  font-size: 16px;
  color: white;
  text-align: center;
  font-weight: bold;
}


/* Cube CSS */

.cube-container {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 200px;  
  position: relative;
  animation: bannermove 15s linear infinite;

}


.cubespinner {
  width: 100px;
  height: 100px;
  position: relative;
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  transform-style: preserve-3d;
  margin-bottom: 10px; 
  
  div {
    position: absolute;
    width: 100px;  
    height: 100px;  
    border: 1px solid #ccc;
    background: rgba(179, 173, 173, 0.4);
    font-size: 40px;  
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0px rgb(221, 173, 221);
  }

  .face1 {
    transform: translateZ(40px);
  }

  .face2 {
    transform: rotateY(90deg) translateZ(40px);
  }

  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
  }

  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
  }

  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
  }

  .face6 {
    transform: rotateX(-90deg) translateZ(40px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateZ(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left:-5780px;
  }
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 50px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 24px auto 30px auto;
  text-align: center;
  width: 90%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  color: #fff;
  height: 300px; 
  width: 100%; 
  background-size: cover;
  background-position: center;
}

.proj-imgbx img.project-img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 15px; 
  box-sizing: border-box; 

}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  width: 20%;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.proj-txtx .languages {
  margin-top: 30px;
  
}

.language-icon {
  margin: 0 5px;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 0 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10px;
  background-color: #121212; 

}

.footer .social-icon {
  text-align: right;
  margin-top: 10px;
}

.footer img {
  width: 26px;
}

.footer .email {
  font-size: 20px;
  margin-top: 10px;
  text-align: right;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #735fc8 111.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.5px;
  margin: 0;
}

.footer .copyright {
  margin-top: 10px;
  text-align: right;
}

.footer h1 {
  font-size: 55px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  margin-top: 50px;
}

.custom-footer-container {
  background-color: #151515; 
  padding: 20px;
  border-radius: 10px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


